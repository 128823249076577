import { render, staticRenderFns } from "./StatusComponent.vue?vue&type=template&id=0d6f01ae&"
import script from "./StatusComponent.vue?vue&type=script&lang=ts&"
export * from "./StatusComponent.vue?vue&type=script&lang=ts&"
import style0 from "./StatusComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VProgressCircular,VSelect})
